/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.fa-hotel:before {
  content: "\f594"; }

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

  $direction:right;
  @mixin direction ($direction:right) {
   @if $direction == right{
    text-align: right;
   }@else{
    text-align: left;
   }
    }    
  
      .rtl{
        direction: rtl;
        text-align: right;
      }
    .rtl .form-control{
      direction: rtl;
      text-align: right;
    }
  
    .rtl-top{
      direction: rtl;
      text-align: right;
    }
    .rtl-top .form-control{
      direction: ltr;
      text-align: left;
    }
   .rtl-dropdown {
    .choices__input--cloned{
      direction: rtl;
        text-align: right;
     }
     .choices__list--single{
      .choices__item--selectable{
        button{
  
          padding: 0;
          background-size: 8px;
          position: relative !important;
          top: 50%;
          // left: 0;
          
          // margin-top: -10px;
          
          // height: 20px;
          // width: 20px;
          // border-radius: 10em;
          // opacity: .5;
          // direction: ltr;
          // text-align: left;
  
        }
      }
     }
   }
  
   formio-alerts{
    display: none;
   }

   .formio-error-wrapper {
    background-color: transparent !important;
    }

    [ref="buttonMessageContainer"] {
      display: none !important;
    }
    .full-screen-background{
      height: fit-content;
      width: fit-content;
    }

    .background-container {
      background-image: url('https://diggri.s3.eu-central-1.amazonaws.com/forms-photos/Background.png');
      background-size: cover;
      background-position: center center;
      height: 100vh;
      width: 100%;
      position: fixed;
    }
     .background-container-logos {
      background-image: url('https://diggri.s3.eu-central-1.amazonaws.com/forms-photos/3+Logos.png');
      background-size: cover;
      background-position: center center;
      height: 10px;
      width: auto;
      position: fixed;
    }

    